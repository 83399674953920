exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact-success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-custom-solutions-enquiry-success-tsx": () => import("./../../../src/pages/custom-solutions-enquiry-success.tsx" /* webpackChunkName: "component---src-pages-custom-solutions-enquiry-success-tsx" */),
  "component---src-pages-custom-solutions-tsx": () => import("./../../../src/pages/custom-solutions.tsx" /* webpackChunkName: "component---src-pages-custom-solutions-tsx" */),
  "component---src-pages-index-factsheet-form-success-tsx": () => import("./../../../src/pages/index-factsheet-form-success.tsx" /* webpackChunkName: "component---src-pages-index-factsheet-form-success-tsx" */),
  "component---src-pages-index-fund-enquiry-success-tsx": () => import("./../../../src/pages/index-fund-enquiry-success.tsx" /* webpackChunkName: "component---src-pages-index-fund-enquiry-success-tsx" */),
  "component---src-pages-index-fund-factsheet-form-success-tsx": () => import("./../../../src/pages/index-fund-factsheet-form-success.tsx" /* webpackChunkName: "component---src-pages-index-fund-factsheet-form-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lead-form-success-tsx": () => import("./../../../src/pages/lead-form-success.tsx" /* webpackChunkName: "component---src-pages-lead-form-success-tsx" */),
  "component---src-pages-press-releases-2024-09-03-caplight-open-partnership-tsx": () => import("./../../../src/pages/press-releases/2024-09-03-caplight-open-partnership.tsx" /* webpackChunkName: "component---src-pages-press-releases-2024-09-03-caplight-open-partnership-tsx" */),
  "component---src-pages-press-releases-2024-09-03-zanbato-open-partnership-tsx": () => import("./../../../src/pages/press-releases/2024-09-03-zanbato-open-partnership.tsx" /* webpackChunkName: "component---src-pages-press-releases-2024-09-03-zanbato-open-partnership-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-unicorn-index-tsx": () => import("./../../../src/pages/unicorn-index.tsx" /* webpackChunkName: "component---src-pages-unicorn-index-tsx" */)
}

